import React from "react";
import "../styling/Contact.css";
import { personalLinks } from "../personalInfo/myInfo";

export default function Contact() {
  return (
    <div className="Contact">
      <div className="container-fluid">
        <div className="contact-info">
          <h1>Let's get in touch!</h1>
          {personalLinks.map((socialMedia) => {
            return (
              <div key={socialMedia.link}>
                <h2>
                  <a
                    href={socialMedia.link}
                    target="_blank"
                    rel="noreferrer"
                    title={socialMedia.title}
                  >
                    {socialMedia.title}
                  </a>
                </h2>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
