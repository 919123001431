import unitednations from "../images/logos/unitednations.png";
import tmflogo from "../images/logos/tmfLogo.jfif";
import esglogo from "../images/logos/esgLogo.jfif";

export const pastLife = {
  title: "My past life",
  paragraph:
    "I am a licensed attorney (US) and in my previous position I worked in the field of compliance. I focused mainly on EU privacy law (perhaps you've heard of the GDPR?) and I am an IAPP Certified Information Privacy Professional (Europe). I've found that the skill set that made me a good lawyer is ideal for coding (e.g. creative problem solving, criticlal thinking, attention to detail, etc.).",
};

export const legalCounsel = {
  title: "Legal Counsel",
  logo: esglogo,
  description:
    "I helped design, implement, and manage the compliance program for the Group companies spread across +20 territories, with a main focus in data privacy (GDPR).",
  imgalt: "esg logo",
  imgClassName: "esglogo",
};

export const legalAssistant = {
  title: "Legal Assistant",
  logo: tmflogo,
  description:
    "I provided compliance services for international businesses: analyzing and applying the Common Reporting Standards (CRS) and the Foreign Account Tax Compliance Act (FATCA).",
  imgalt: "tmf logo",
  imgClassName: "tmflogo",
};
export const legalIntern = {
  title: "Legal Intern",
  court: "International Criminal Tribunal for the former Yugoslavia",
  logo: unitednations,
  description:
    "I provided legal support to the Judges on the case of The Prosecutor v. Ratko Mladic. I conducted legal research, participated in deliberations, processed evidence and witness testimony, reported on court proceedings, and drafted Decisions and parts of the Judgment.",
  imgalt: "un-logo",
  imgClassName: "unlogo",
  className: "ICTY",
};

export const universities = [
  {
    university: "University of Florida, Levin College of Law",
    degree: "Juris Doctor",
    icon: "fas fa-balance-scale",
  },
  {
    university: "Universiteit van Amsterdam",
    degree: "LLM Public International Law",
    icon: "fas fa-graduation-cap",
  },
  {
    university: "University of Florida",
    degree: "Bachelor of Criminology",
    icon: "fas fa-university",
  },
];

export const licenses = [
  {
    title: "License to practice law",
    info: "New York State",
    icon: "fas fa-gavel",
  },
  {
    title: "Certified Information Privacy Professional (Europe)",
    icon: "fas fa-user-secret",
  },
];
