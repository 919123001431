import React from "react";
import "../styling/Home.css";
import Footer from "../components/Footer";
import { projects } from "../personalInfo/projects";
import { credit } from "../personalInfo/myInfo";
import Buttons from "../components/Buttons";

export default function Home() {
  const featuredProjects = projects.filter(
    (project) => project.featured === true
  );

  return (
    <div className="Home">
      <div className="hero">
        <p>Hello, I'm</p>
        <h1>{credit?.name}</h1>
        <h3>
          {credit?.newJobTitle} based in {credit?.currentCity}
        </h3>
        <Buttons button="Contact Me" />
      </div>
      <div className="FeaturedProjects container">
        <h2 className="title">Featured Projects</h2>
        <div className="row">
          <div className="col d-none d-lg-block">
            <a
              href={featuredProjects[0]?.site}
              target="_blank"
              rel="noreferrer"
              title={featuredProjects[0]?.name}
            >
              <img
                src={featuredProjects[0]?.imgsrc}
                className={featuredProjects[0]?.imgClassName}
                alt={featuredProjects[0]?.imgalt}
              />
            </a>
          </div>
          <div className="col project-description">
            <h3>{featuredProjects[0]?.name}</h3>
            <p>{featuredProjects[0]?.description}</p>
            <Buttons button="View Projects" />
          </div>
        </div>
        <div className="row">
          <div className="col project-description">
            <h3>{featuredProjects[1]?.name}</h3>
            <p>{featuredProjects[1].description}</p>
            <Buttons button="View Projects" />
          </div>
          <div className="col d-none d-lg-block">
            <a
              href={featuredProjects[1]?.site}
              target="_blank"
              rel="noreferrer"
              title={featuredProjects[1]?.name}
            >
              <img
                src={featuredProjects[1]?.imgsrc}
                alt={featuredProjects[1]?.imgalt}
                className={featuredProjects[1]?.imgClassName}
              />
            </a>
          </div>
        </div>
        <div className="row last-row">
          <div className="col d-none d-lg-block">
            <a
              href={featuredProjects[2]?.site}
              target="_blank"
              rel="noreferrer"
              title={featuredProjects[2]?.name}
            >
              <img
                src={featuredProjects[2]?.imgsrc}
                className="img-fluid"
                alt={featuredProjects[2]?.imgalt}
              />
            </a>
          </div>
          <div className="col project-description">
            <h3>{featuredProjects[2]?.name}</h3>
            <p>{featuredProjects[2]?.description}</p>
            <Buttons button="View Projects" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
