import React from "react";
import Footer from "../components/Footer";
import { projects } from "../personalInfo/projects";
import "../styling/Portfolio.css";

export default function Portfolio() {
  return (
    <div className="Portfolio">
      <h1>Take a look at what I've been working on!</h1>
      <div className="container">
        <div className="AllProjects row">
          {projects.map((project, index) => {
            return (
              <div className="col-6 each-project" key={index}>
                <a
                  href={project.site}
                  target="_blank"
                  rel="noreferrer"
                  title={project.name}
                >
                  <img
                    className="img-fluid"
                    src={project.imgsrc}
                    alt={project.imgalt}
                    height={project.imgalt === "statiecheck" ? 200 : 434}
                    width={project.imgalt === "statiecheck" ? 300 : 623}
                  />
                </a>
                <h2>{project.name}</h2> Technologies:
                {project?.github && (
                  <a
                    href={project.github}
                    target="_blank"
                    rel="noreferrer"
                    title="Github"
                  >
                    <p className="github-project-link">
                      {project.info.join(", ")}
                    </p>
                  </a>
                )}
                {!project?.github && (
                  <p className="github-project-link">
                    {project.info.join(", ")}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
