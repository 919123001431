import React from "react";
import "../styling/components.css";
import Buttons from "./Buttons";

export default function WorkInquiry() {
  return (
    <div className="WorkInquiry d-none d-lg-block container">
      <div className="row">
        <div className="col-lg-6 left-side">
          <h3>Work Inquiry</h3>
          <p className="text-muted">Let's work together</p>
        </div>
        <div className="col-lg-6 right-side">
          <Buttons button="Contact Me" />
        </div>
      </div>
    </div>
  );
}
