import React from "react";
import { NavLink } from "react-router-dom";
import "../styling/components.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

export default function Navigation() {
  return (
    <div className="Navigation">
      <Navbar
        fixed="top"
        className="navigation-bar"
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
      >
        <Container fluid>
          <NavLink className="navbar-brand my-name-nav" to="/" title="homepage">
            Karen Stewart
          </NavLink>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavLink className="nav-link" to="/" title="homepage">
                Home
              </NavLink>
              <NavLink className="nav-link" to="/about" title="about me">
                About me
              </NavLink>
              <NavLink
                className="nav-link"
                to="/portfolio"
                title="portfolio page"
              >
                Portfolio
              </NavLink>
              <NavLink className="nav-link" to="/contact" title="contact page">
                Contact
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
