import KarenStewartCV from "../personalInfo/Karen_Stewart.pdf";
import MyPhoto from "../images/MyPhoto.JPG";

export const personalLinks = [
  {
    title: "Github",
    link: "https://github.com/KarenStewart1",
    icon: "fab fa-github",
  },
  {
    title: "LinkedIn",
    link: "https://www.linkedin.com/in/karenstewartnl/",
    icon: "fab fa-linkedin",
  },
  {
    title: "iamkarenstewart@gmail.com",
    link: "mailto:iamkarenstewart@gmail.com",
    icon: "far fa-envelope",
  },
];

export const credit = {
  name: "Karen Stewart",
  newJobTitle: "Software Engineer",
  currentCity: "Amsterdam",
  link: "https://www.linkedin.com/in/karenstewartnl/",
  intro:
    "I realized it is never too late to make a change when you find something you are passionate about.",
  tagLine: "Lawyer turned software engineer",
  cvPDF: KarenStewartCV,
  photo: MyPhoto,
  languages: [{ language: "English", level: "Native" }],
  citizenship: "Dual Finland & US citizen",
};
