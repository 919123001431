import { credit } from "../personalInfo/myInfo";

export default function Buttons(props) {
  const buttonName = props.button;

  if (buttonName === "View Projects") {
    return (
      <a href="./portfolio" className="button-white">
        {buttonName}
      </a>
    );
  } else if (buttonName === "Contact Me") {
    return (
      <a href="/contact" className="button-purple">
        {buttonName}
      </a>
    );
  } else if (buttonName === "Download CV") {
    return (
      <a href={credit.cvPDF} download className="button-purple about-button">
        {buttonName}
      </a>
    );
  }
  return null;
}
