import React from "react";
import "../styling/components.css";
import { personalLinks } from "../personalInfo/myInfo";

export default function Footer() {
  return (
    <div className="Footer">
      <footer>
        <div className="social-links d-flex justify-content-center">
          {personalLinks.map((socialMedia) => {
            return (
              <a
                href={socialMedia.link}
                key={socialMedia.link}
                target="_blank"
                rel="noreferrer"
              >
                <i className={socialMedia.icon}></i>
              </a>
            );
          })}
        </div>
      </footer>
    </div>
  );
}
